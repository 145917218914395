<template>
  <div class="add-pro-wrapper">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="应用设置"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="add-pro-content">
      <el-row :gutter="15">
      <!-- 左模块 -->
        <el-col :span="22">
          <!-- 客服信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="2">
                <div class="part-info">
                  <div class="title">客服</div>
                  <div class="info">客服信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="22">
                <el-row :gutter="20">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.wechat">
                        <template slot="prepend">微信号</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.wechat_qrcode_imgurl">
                        <template slot="prepend">微信二维码</template>
                      </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt10">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.qq">
                        <template slot="prepend">qq</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.qq_qrcode_imgurl">
                        <template slot="prepend">qq二维码</template>
                      </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt10">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.taobao">
                        <template slot="prepend">淘宝</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.taobao_qrcode_imgurl">
                        <template slot="prepend">淘宝二维码</template>
                      </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.taobao_webside">
                        <template slot="prepend">淘宝店铺</template>
                      </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.show_links">
                        <template slot="prepend">支持的客服方式</template>
                      </el-input>
                  </el-col>
                </el-row>
                <!-- <el-input
                  type="textarea"
                  style="margin-top:10px"
                  :autosize="{ minRows: 4, maxRows: 6}"
                  placeholder="请输入内容"
                  v-model="form.tips">
                </el-input> -->
              </el-col>
            </el-row>
          </el-card>

          <!-- 通用配置 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="2">
                <div class="part-info">
                  <div class="title">通用配置</div>
                  <!-- <div class="info">客服信息</div> -->
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="22">
                <el-row :gutter="20">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.offical_webside">
                        <template slot="prepend">软件官网</template>
                      </el-input>
                  </el-col>
                </el-row>
              </el-col>
              </el-row>
          </el-card>

          <!-- 通知信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="2">
                <div class="part-info">
                  <div class="title">通知</div>
                  <div class="info">通知信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="22">
                <el-row :gutter="20" style="margin-bottom:20px">
                  <el-col :span="3">
                    <el-switch
                      v-model="form.is_open_notice"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                  </el-col>
                  <el-col :span="21">
                    总开关
                  </el-col>
                </el-row>

                <!-- 通知1 -->
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-switch
                      v-model="form.is_open_notice_1"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                  </el-col>
                  <el-col :span="21">
                    <el-input
                      type="textarea"
                      style="margin-top:10px"
                      :autosize="{ minRows: 4, maxRows: 10}"
                      placeholder="请输入内容"
                      v-model="form.notice_1_content">
                    </el-input>
                  </el-col>
                </el-row>
                <!-- 通知2 -->
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-switch
                      v-model="form.is_open_notice_2"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                  </el-col>
                  <el-col :span="21">
                    <el-row :gutter="20" class="mt10">
                      <el-col :span="24">
                          <el-input placeholder="请输入内容" v-model="form.notice_2_title">
                            <template slot="prepend">通知2标题</template>
                          </el-input>
                      </el-col>
                    </el-row>
                    <el-input
                      type="textarea"
                      style="margin-top:10px"
                      :autosize="{ minRows: 4, maxRows: 10}"
                      placeholder="请输入内容"
                      v-model="form.notice_2_content">
                    </el-input>
                  </el-col>
                </el-row>
                <!-- 通知3 -->
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-switch
                      v-model="form.is_open_notice_3"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="1"
                      :inactive-value="0">
                    </el-switch>
                  </el-col>
                  <el-col :span="21">
                    <el-input
                      type="textarea"
                      style="margin-top:10px"
                      :autosize="{ minRows: 4, maxRows: 10}"
                      placeholder="请输入内容"
                      v-model="form.notice_3_content">
                    </el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-card>

        </el-col>

        <!-- 右模块 -->
        <el-col :span="2">
        </el-col>
      </el-row>
    </div>
    <!-- 添加商品表单结束 -->




  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
export default {
  name:'addProducts',
    components:{
      Title
    },
    data(){
      return{
        form:{},
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
          {name:'所有',value:null},
        ],
        devices_list:[],
        notice_levels:[
          {name:'提醒通知',value:2},
          {name:'弹窗通知',value:3},
        ]
      }
    },
    methods:{
      save(){
        console.log(this.form)
        apis.appconfig.update(this.form).then(res=>{
          this.read()
        }).catch(err=>{
          this.$message({msg:err,type:'error'})
          console.log('err',err)
        })
      },
      read(){
        apis.appconfig.read().then(res=>{
          console.log('res',res)
          // this.form_loading = false;
          this.form = res.data.data
        }).catch(err=>{
          this.form_loading = false;
          console.log('err',err)
        })
      },
    },
    mounted(){
        this.read()
    },
    computed:{

    },
}
</script>

<style lang="scss">
.add-pro-wrapper{
  .add-pro-content{
    box-sizing: border-box;
    width: 100%;
    padding:15px;
  }
  .box-card{
    margin-bottom:5px;
  }
  .part-info{
    // @include flex_ccc();
    // background: brown;
    .title{
      font-size: 18px;
      color:#333;
    }
    .info{
      font-size: 12px;
      color:#999;
    }
  }
}
</style>