import request from '@/api/request.js'; //请求对象
 
 const apis = {
	
	delete (params){
		return request({url: `/admin_suggestions/delete`, method: 'POST', data:params})
	},
	read (params){
		return request({url: `/admin_suggestions/read`, params:params, method: 'GET'})
	},
	// answer (params){
	// 	return request({url: `/admin_suggestions/answer`, params:params, method: 'POST'})
	// },
 }
 
 export default apis;