import request from '@/api/request.js'; //请求对象
 
 const apis = {
	
	delete (params){
		return request({url: `/admin_find_sound/delete`, method: 'POST', data:params})
	},
	ReadOnlineCountLog (params){
		return request({url: `/admin_index/ReadOnlineCountLog`, params:params, method: 'GET'})
	},
	getOnlineDataPersent (params){
		return request({url: `/admin_index/getOnlineDataPersent`, params:params, method: 'GET'})
	},
	answer (params){
		return request({url: `/admin_find_sound/answer`, params:params, method: 'POST'})
	},
 }
 
 export default apis;