import request from '@/api/request.js'; //请求对象
 
 const apis = {
	
	delete (params){
		return request({url: `/admin_user_search/delete`, method: 'POST', data:params})
	},
	read (params){
		return request({url: `/admin_user_search/read`, params:params, method: 'GET'})
	},
	searchTest (params){
		return request({url: `/admin_user_search/searchTest`, params:params, method: 'GET'})
	},
 }
 
 export default apis;