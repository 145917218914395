// import request from '@/api/request.js'; //请求对象
import axios from 'axios';
var instance = axios.create({
    timeout: (1000 * 60 * 20),
	baseURL:'/apis',
    // baseURL:base.getServerInfo().api_server
	headers: { 'content-type': 'multipart/form-data' }
});
 const apis = {
	
	up (params){
		return instance.post(`/upload/up`,params)
	},
	upAliyunOss (params){
		return instance.post(`/util/upload/upfile`,params)
	},
 }
 
 export default apis;