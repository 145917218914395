import request from '@/api/request.js'; //请求对象
 
 const apis = {
	
	create (params){
		return request({url: `/appversion/create`, method: 'POST', data:params})
	},
	delete (params){
		return request({url: `/appversion/delete`, method: 'POST', data:params})
	},
	update (params){
		return request({url: `/appversion/update`, method: 'POST', data:params})
	},
	read (params){
		return request({url: `/appversion/read`, params:params, method: 'GET'})
	},
	readOneAppversion (params){
		return request({url: `/appversion/readOneAppversion`, params:params, method: 'GET'})
	},
 }
 
 export default apis;